import EventEmitter from 'events';

import { io } from 'socket.io-client';

import FightEnd from '../interfaces/fightEnd';
import Item from '../interfaces/item';
import Monster from '../interfaces/monster';
import PlayerHit from '../interfaces/playerHit';

export default class WebSocketClient extends EventEmitter {
    constructor() {
        super();

        const client = io(process.env.REACT_APP_SOCKET_HOST!, {
            secure: false,
            query: {
                token: process.env.REACT_APP_SOCKET_AUTH_TOKEN!,
            },
        });

        client.on('connect', () => {
            console.info('Connection opened');
        });

        client.on('fightStarted', (monster: Monster) => {
            console.info(`Fight started: ${monster.name} (${monster.life} LP)`);

            this.emit('fightStarted', monster);
        });

        client.on('fightPlayerHit', (playerHit: PlayerHit) => {
            console.info(`Player ${playerHit.username} hit (${playerHit.damages})`);

            this.emit('fightPlayerHit', playerHit);
        });

        client.on('fightLootItem', (item) => {
            console.info(`Fight loot item: ${item.name}`);

            this.emit('fightLootItem', item);
        });

        client.on('fightLootItemWinner', (winner) => {
            console.info(`Fight loot item winner: ${winner}`);

            this.emit('fightLootItemWinner', winner);
        });

        client.on('fightEnded', (result: FightEnd) => {
            console.info('Fight ended');

            this.emit('fightEnded', result);
        });

        client.on('fightStopped', () => {
            console.info('Fight canceled');

            this.emit('fightEnded');
        });

        client.on('disconnect', () => {
            console.info('Connection closed');
        });
    }
}
