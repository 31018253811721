export default {
    '1eee2a4a-437d-42cf-8e88-27b5d6d04a25': 'https://viewers-adventure.com/img/bestiaire/bandits/bandino_le_garde_du_corps.png',
    '2c5b60d3-aa1b-4aca-bc57-39c8e66dad56': 'https://viewers-adventure.com/img/bestiaire/bandits/bandit.png',
    '6baf4c59-6459-4dd7-8396-e4586637368b': 'https://viewers-adventure.com/img/bestiaire/bandits/marlone_le_bandit_trouble-fete.png',
    'e0134833-c591-4e41-b527-5a67269ad291': 'https://viewers-adventure.com/img/bestiaire/bandits/chef_des_bandits.png',
    '87be1879-92dc-11eb-9d77-fa163e42b9de': 'https://viewers-adventure.com/img/bestiaire/blobs/les_freres_blobby.png',
    '87be1d75-92dc-11eb-9d77-fa163e42b9de': 'https://viewers-adventure.com/img/bestiaire/blobs/blobeux_l_ingenieux.png',
    '87be1e82-92dc-11eb-9d77-fa163e42b9de': 'https://viewers-adventure.com/img/bestiaire/blobs/blobyte_le_parasite.png',
    '87be1f39-92dc-11eb-9d77-fa163e42b9de': 'https://viewers-adventure.com/img/bestiaire/blobs/roi_blob.png',
    '1e74a9ca-1afd-11ec-9d77-fa163e42b9de': 'https://viewers-adventure.com/img/bestiaire/exclusif/piñata.png',
} as Record<string, string>;
