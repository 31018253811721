import classNames from 'classnames';
import React from 'react';
import slugify from 'slugify';

import CustomCSS from './interfaces/customCSS';
import Item from './interfaces/item';
import Monster from './interfaces/monster';
import PlayerHit from './interfaces/playerHit';
import WebSocketClient from './socket/webSocketClient';
import monsterImageMapping from './utilities/monsterImageMapping';
import rarityMapping from './utilities/rarityMapping';

// eslint-disable-next-line quotes
slugify.extend({ '-': '-', "'": '_' });

const bridge = new WebSocketClient();

let fightersDisplayInterval: NodeJS.Timeout;
let terminateFightTimeout: NodeJS.Timeout | void;
let fightersDamages: [string, number][] = [];

const App: React.FC = () => {
    const [fightPop, setFightPop] = React.useState<boolean>(false);

    const [monster, setMonster] = React.useState<Monster | null>(null);

    const [isHit, setIsHit] = React.useState<boolean>(false);
    const [hits, setHits] = React.useState<Array<[string, number]>>([]);

    const [damages, setDamages] = React.useState<number>(0);

    const [lootedItems, setLootedItems] = React.useState<number>(0);
    const [lootItem, setLootItem] = React.useState<Item | null>(null);
    const [lootItemWinner, setLootItemWinner] = React.useState<string | false | null>(null);

    const terminateFight = (delay?: number): NodeJS.Timeout | void => {
        const terminate = () => {
            setFightPop(false);

            setMonster(null);

            setIsHit(false);
            clearInterval(fightersDisplayInterval);
            setHits([]);

            setDamages(0);

            setLootItem(null);
            setLootItemWinner(null);
            setLootedItems(0);
        };

        if (delay) {
            return setTimeout(terminate, delay);
        } else {
            terminate();
        }
    };

    React.useEffect(() => {
        let winners = 0;

        // Fight started, start listening to damages
        bridge.on('fightStarted', (monster) => {
            winners = 0;

            if (terminateFightTimeout) {
                clearTimeout(terminateFightTimeout);
            }

            terminateFight();

            setMonster(monster);
            setFightPop(true);

            fightersDisplayInterval = setInterval(() => {
                const fightersGroupDamages = fightersDamages.slice(0, 5);

                if (fightersGroupDamages.length > 0) {
                    setHits(fightersGroupDamages);

                    fightersDamages = fightersDamages.slice(fightersGroupDamages.length);
                }
            }, 50);
        });

        // Player hit, register event into external fighters damages holder
        bridge.on('fightPlayerHit', (playerHit: PlayerHit) => {
            fightersDamages.push([playerHit.username, playerHit.damages]);
        });

        bridge.on('fightLootItem', (item: Item) => {
            const onLootItem = () => {
                setLootItem(item);
                setLootItemWinner(null);

                setLootedItems((previous) => previous + 1);
            };

            if (winners === 0) {
                setTimeout(() => onLootItem(), 1500);
            } else {
                onLootItem();
            }

            winners++;
        });

        bridge.on('fightLootItemWinner', (winner) => {
            if (winner === null) {
                setLootItemWinner(false);
            } else {
                setLootItemWinner(winner);
            }
        });

        bridge.on('fightEnded', (result) => {
            if (result && winners === 1) {
                setTimeout(() => {
                    terminateFight();
                }, 2000);
            } else {
                terminateFight();
            }
        });
    }, []);

    React.useEffect(() => {
        if (hits.length > 0) {
            setDamages((currentDamages) => Math.min(monster!.life, currentDamages + hits.reduce((total, [, currentHit]) => total + currentHit, 0)));

            setIsHit(true);
        }
    }, [hits]);

    if (lootItem) {
        return (
            <>
                <div key={`${lootItem.id}-${lootedItems}`} className="drop reveal">
                    <img
                        key={`${lootItem.id}-image`}
                        className="item image"
                        src={`https://viewers-adventure.com/img/loots/${slugify(lootItem.name.toLowerCase(), {
                            replacement: '_',
                        })}@3x.png`}
                        style={
                            {
                                '--delay': lootedItems === 1 ? '8s' : '4s',
                            } as any
                        }
                    />
                    <div
                        key={`${lootItemWinner || '__NONE__'}-${lootedItems}`}
                        className={classNames('text', {
                            reveal: lootItemWinner !== null,
                        })}
                    >
                        {lootItemWinner === false && (
                            <>
                                Personne ne remporte <div className={`rarity__${lootItem.rarityId}`}>{lootItem.name}</div> !
                            </>
                        )}
                        {lootItemWinner !== null && lootItemWinner !== false && (
                            <>
                                {lootItemWinner} remporte <div className={`rarity__${lootItem.rarityId}`}>{lootItem.name}</div> !
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }

    if (lootedItems === 0 && monster) {
        return (
            <div style={{ '--document-height': `${document.body.offsetHeight}px` } as CustomCSS}>
                <audio autoPlay>
                    <source src="/assets/sounds/horn.mp3" type="audio/mp3" about="Entering sound" />
                </audio>
                {damages === monster!.life && (
                    <audio autoPlay>
                        <source src="/assets/sounds/death.mp3" type="audio/mp3" about="Death sound" />
                    </audio>
                )}
                <div className="monster_rarity__pop">
                    <img src="/assets/images/pop.png" />
                    <div className={`text rarity__${monster!.rarityId}`}>{rarityMapping[monster!.rarityId]} !</div>
                </div>
                <div
                    className={classNames('monster', {
                        hit: isHit,
                        die: monster.life! - damages <= 0,
                        enter: fightPop,
                    })}
                    onAnimationEnd={(event) => {
                        if (event.animationName === 'shake' && isHit) {
                            setIsHit(false);
                        } else if (event.animationName === 'monsterEnter') {
                            setFightPop(false);

                            setIsHit(true);
                        }
                    }}
                >
                    <div className="enter__text">
                        <b>{monster!.name}</b> apparait !
                    </div>
                    <img className="image" src={monsterImageMapping[monster.id!]} />
                    <div className="health">
                        <div className="health__text">
                            {monster!.life - damages} / {monster!.life}
                        </div>
                        <div className="health__bar" style={{ width: `${((monster!.life - damages) / monster.life!) * 100}%` }} />
                    </div>
                    <div className="hit__container">
                        {hits.map(([nickname, damages]) => (
                            <div key={`hit-${nickname}`} className="hit__text">
                                {nickname}
                                <div className="damages">- {damages}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

export default App;
